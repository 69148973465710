import DataFilterHelper from './DataFilterHelper';
import { FILTER_TYPE_SORT, FILTER_TYPE_STAFF_PICK } from '@/helpers/data-filter/DataFilterTypes';
import { isCurrentGeoPositionExist } from '@/helpers/GeolocationHelper';

class DestinationMapPageFilterHelper extends DataFilterHelper {
  defaultValues = {
    [FILTER_TYPE_STAFF_PICK]: null,
    [FILTER_TYPE_SORT]: null,
  };
  validValuesTypes = {
    boolean: [FILTER_TYPE_STAFF_PICK],
  };

  /**
   * @returns {FilterGroup[]}
   */
  prepareFilterGroups() {
    /**
     * @type {FilterGroup[]}
     */
    const groups = [
      this.addRadioGroupItems({
        itemsType: FILTER_TYPE_STAFF_PICK,
        items: [
          {
            title: 'Show only Staff picks',
            value: true,
          },
        ],
      }),
    ];

    const sortItems = [
      {
        title: 'Sort alphabetically',
        value: { column: 'title' },
      },
    ];

    if (isCurrentGeoPositionExist()) {
      sortItems.unshift({
        title: 'Sort by distance',
        value: { column: 'distance' },
      });
    }

    groups.push(
      this.addRadioGroupItems({
        itemsType: FILTER_TYPE_SORT,
        items: sortItems,
      })
    );

    return groups.filter((v) => v);
  }

  prepareFilterValuesToQueryVariables(groups = {}) {
    const transformed = super.prepareFilterValuesToQueryVariables(groups);

    return {
      filters: {
        staff_pick: transformed[FILTER_TYPE_STAFF_PICK],
      },
      sort: transformed[FILTER_TYPE_SORT],
    };
  }
}

export default DestinationMapPageFilterHelper.create();
